import { createSlice } from '@reduxjs/toolkit'

const initialState: {
  [queryID: string]: {
    rowData: {
      [key: string]: any
    }[]
    colDefs: any
  }
} = {}
const queryResultSlice = createSlice({
  name: 'queryResults',
  initialState,
  reducers: {
    setQueryResult(state, action) {
      const { queryID, result } = action.payload
      return {
        ...state,
        [queryID]: result,
      }
    },
  },
})

export const { setQueryResult } = queryResultSlice.actions

export default queryResultSlice.reducer
