import { AgentJob, WorkspaceState } from '@/lib/common/types'
import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'

const initialState: {
  [user_id: string]: WorkspaceState
} = {}
const workspaceStateSlice = createSlice({
  name: 'queryResults',
  initialState,
  reducers: {
    initWorkspaceState(state, action) {
      const { user_id, workspaceState } = action.payload
      return {
        ...state,
        [user_id]: workspaceState,
      }
    },
    setClean(state, action) {
      const { user_id } = action.payload
      return {
        ...state,
        [user_id]: {
          ...state[user_id],
          lastUpdatedAt: Date.now(),
          isDirty: false,
        },
      }
    },
    createQueryTab(state, action) {
      const { user_id } = action.payload
      return {
        ...state,
        [user_id]: {
          ...state[user_id],
          tabs: [
            ...state[user_id].tabs,
            {
              type: 'query',
              id: uuidv4(),
              name: 'Untitled',
              code: '',
            },
          ],
          selectedTabIndex: state[user_id].tabs.length,
          lastUpdatedAt: Date.now(),
          isDirty: true,
        },
      }
    },
    createTableTab(state, action) {
      const { user_id, name } = action.payload
      return {
        ...state,
        [user_id]: {
          ...state[user_id],
          tabs: [
            {
              type: 'table',
              id: uuidv4(),
              name,
            },
            ...state[user_id].tabs.filter((tab) => tab.type !== 'table'),
          ],
          selectedTabIndex: 0,
          lastUpdatedAt: Date.now(),
          isDirty: true,
        },
      }
    },
    changeTabName(state, action) {
      const { user_id, tab_id, name } = action.payload
      return {
        ...state,
        [user_id]: {
          ...state[user_id],
          tabs: state[user_id].tabs.map((tab) =>
            tab.id === tab_id ? { ...tab, name } : tab,
          ),
          lastUpdatedAt: Date.now(),
          isDirty: true,
        },
      }
    },
    updateTabCode(state, action) {
      const { user_id, tab_id, code } = action.payload
      return {
        ...state,
        [user_id]: {
          ...state[user_id],
          tabs: state[user_id].tabs.map((tab) =>
            tab.id === tab_id ? { ...tab, code } : tab,
          ),
          lastUpdatedAt: Date.now(),
          isDirty: true,
        },
      }
    },
    deleteTab(state, action) {
      const { user_id, tab_id } = action.payload
      return {
        ...state,
        [user_id]: {
          ...state[user_id],
          tabs: state[user_id].tabs.filter((tab) => tab.id !== tab_id),
          selectedTabIndex: Math.min(
            state[user_id].selectedTabIndex,
            state[user_id].tabs.length - 2,
          ),
          lastUpdatedAt: Date.now(),
          isDirty: true,
        },
      }
    },
    selectTab(state, action) {
      const { user_id } = action.payload
      return {
        ...state,
        [user_id]: {
          ...state[user_id],
          selectedTabIndex: action.payload.index,
          lastUpdatedAt: Date.now(),
          isDirty: true,
        },
      }
    },
    createQuery(state, action) {
      const { user_id, tab_id, query_id, query } = action.payload
      return {
        ...state,
        [user_id]: {
          ...state[user_id],
          tabs: state[user_id].tabs.map((tab) =>
            tab.id === tab_id
              ? {
                  ...tab,
                  queries: [
                    {
                      id: query_id,
                      query,
                      startTime: Date.now(),
                    },
                    ...(tab.queries || []),
                  ],
                }
              : tab,
          ),
          lastUpdatedAt: Date.now(),
          isDirty: true,
        },
      }
    },
    updateQuery(state, action) {
      const { user_id, tab_id, query_id, query, query_status, error } =
        action.payload
      const oldQuery = state[user_id].tabs
        .find((tab) => tab.id === tab_id)
        ?.queries?.find((q) => q.id === query_id)
      if (!oldQuery) return state

      const newQuery = {
        ...oldQuery,
      }
      if (query) newQuery.query = query
      if (query_status) newQuery.query_status = query_status
      if (error) newQuery.error = error

      if (['SUCCESS', 'FAILURE', 'REVOKED'].includes(query_status || '')) {
        newQuery.endTime = newQuery.endTime || Date.now()
      }
      return {
        ...state,
        [user_id]: {
          ...state[user_id],
          tabs: state[user_id].tabs.map((tab) =>
            tab.id === tab_id
              ? {
                  ...tab,
                  queries: (tab.queries || []).map((q) =>
                    q.id === query_id ? newQuery : q,
                  ),
                }
              : tab,
          ),
          lastUpdatedAt: Date.now(),
          isDirty: true,
        },
      }
    },
    deleteQuery(state, action) {
      const { user_id, tab_id, query_id } = action.payload
      return {
        ...state,
        [user_id]: {
          ...state[user_id],
          tabs: state[user_id].tabs.map((tab) =>
            tab.id === tab_id
              ? {
                  ...tab,
                  queries: (tab.queries || []).filter((q) => q.id !== query_id),
                }
              : tab,
          ),
          lastUpdatedAt: Date.now(),
          isDirty: true,
        },
      }
    },
    updateTabs(state, action) {
      const { user_id, tabs } = action.payload
      return {
        ...state,
        [user_id]: {
          ...state[user_id],
          tabs: tabs,
          lastUpdatedAt: Date.now(),
          isDirty: true,
        },
      }
    },
  },
})

export const {
  initWorkspaceState,
  setClean,
  createQueryTab,
  createTableTab,
  changeTabName,
  updateTabCode,
  deleteTab,
  selectTab,
  createQuery,
  updateQuery,
  deleteQuery,
  updateTabs,
} = workspaceStateSlice.actions

export default workspaceStateSlice.reducer
