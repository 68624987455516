import { AgentJob } from '@/lib/common/types'
import { createSlice } from '@reduxjs/toolkit'

const initialState: {
  [agentJobId: string]: {
    agentJobId: string
    status?: number
    agentJob?: AgentJob
  }
} = {}
const agentJobsSlice = createSlice({
  name: 'agentJobs',
  initialState,
  reducers: {
    setAgentJobStatus(state, action) {
      const { agentJobId, status } = action.payload
      state[agentJobId] = {
        agentJobId,
        status,
        agentJob: state[agentJobId]?.agentJob,
      }
    },
    setAgentJobStatusBatch(state, action) {
      const agentJobStatuses = action.payload
      agentJobStatuses.forEach((job: any) => {
        state[job.id] = {
          agentJobId: job.id,
          status: job.status,
          agentJob: state[job.id]?.agentJob,
        }
      })
    },
    setAgentJob(state, action) {
      const { agentJobId, agentJob } = action.payload
      state[agentJobId] = {
        agentJobId,
        agentJob,
        status: state[agentJobId]?.status,
      }
    },
  },
})

export const { setAgentJobStatus, setAgentJobStatusBatch, setAgentJob } =
  agentJobsSlice.actions

export default agentJobsSlice.reducer
