import { RoeFile, RoeFileDetailed } from '@/lib/common/types'
import { createSlice } from '@reduxjs/toolkit'

const initialState: {
  [fileId: string]: RoeFileDetailed
} = {}
const datasetFilesSlice = createSlice({
  name: 'datasetFiles',
  initialState,
  reducers: {
    addDatasetFile(state, action) {
      const file = action.payload
      state[file.id] = file
    },
    addDatasetFiles(state, action) {
      const files = action.payload
      files.forEach((file: RoeFileDetailed) => {
        state[file.id] = file
      })
    },
    removeDatasetFile(state, action) {
      const fileId = action.payload
      delete state[fileId]
    },
  },
})

export const { addDatasetFile, addDatasetFiles, removeDatasetFile } =
  datasetFilesSlice.actions

export default datasetFilesSlice.reducer
