import { Dataset, RoeFile } from '@/lib/common/types'
import { createSlice } from '@reduxjs/toolkit'

const initialState: {
  [datasetId: string]: Dataset
} = {}
const datasetsSlice = createSlice({
  name: 'datasets',
  initialState,
  reducers: {
    addDataset(state, action) {
      const dataset = action.payload
      state[dataset.id] = dataset
    },
    addDatasets(state, action) {
      const datasets = action.payload
      datasets.forEach((dataset: Dataset) => {
        state[dataset.id] = dataset
      })
    },
    removeDataset(state, action) {
      const datasetId = action.payload
      delete state[datasetId]
    },
  },
})

export const { addDataset, addDatasets, removeDataset } = datasetsSlice.actions

export default datasetsSlice.reducer
